import AppBanner from "../components/Footer/AppBanner";
import AppViews from "../components/Pages/Home/AppViews";
import FindLocalPlayers from "../components/Pages/Home/FindLocalPlayers";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import { useAppContext } from "../_context";
import Stats from "../components/Stats/Stats";
import FeaturedBy from "../components/Misc/FeaturedBy";
import LeaveFeedback from "../components/LeaveFeedback";
import Testimonials from "../components/Testimonials";
import Hero from "../components/Pages/Home/Hero";
import { useEffect, useState } from "react";
import Contentful from "../utilities/Contentful";
import { getLinkToContentfulMedia } from "../utilities/Helpers";
import AppStoreLinks from "../components/Misc/AppStore/AppStoreLinks";
import Partners from "../components/Partners";

const Home = () => {
  const [{ tx, locale }] = useAppContext();
  const [stats, setStats] = useState([]);
  const [testimonials, setTestimonials] = useState([]);

  const howItWorks = () => {
    return [
      tx["Download the RacketPal app and create your free account."],
      tx["Search local players and find the perfect partners in your area."],
      tx["Set up a match for singles, doubles, or social."],
      tx["Meet up and play at your favourite local venues."],
      tx["Add scores in the app and start planning your next match."],
    ];
  };

  const findLocalPlayersData = {
    title: tx["The easy way to find local players"],
    paragraph:
      tx[
        "With RacketPal you can find and connect with local racket sports players in seconds. No more twiddling your thumbs wishing you were playing on the court – now you can get access to thousands of players around you."
      ],
    secondParagraph:
      tx[
        "Up for a game? Download the free RacketPal app now and start finding pals in your area today."
      ],
    Button: () => <AppStoreLinks white={false} />,
  };

  useEffect(() => {
    async function getStats() {
      const results = await new Contentful().fetchValueOnly({
        content_type: "statistics",
        "fields.slug[in]": "players,clubs,coaches,matches-played",
      });
      const intl = new Intl.NumberFormat(locale);

      setStats([
        {
          svg: "/img/stat/stat-1.svg",
          subheading: tx["nPlayers"],
          label:
            intl.format(results.find((x) => x.slug === "players").value) + "+",
        },
        {
          svg: "/img/stat/stat-2.svg",
          subheading: tx["nClubs"],
          label:
            intl.format(results.find((x) => x.slug === "clubs").value) + "+",
        },
        {
          svg: "/img/stat/stat-3.svg",
          subheading: tx["nCoaches"],
          label:
            intl.format(results.find((x) => x.slug === "coaches").value) + "+",
        },
        {
          svg: "/img/stat/stat-4.svg",
          subheading: tx["nConversations"],
          label:
            intl.format(
              results.find((x) => x.slug === "matches-played").value,
            ) + "M+",
        },
      ]);
    }

    async function getTestimonials() {
      const results = await new Contentful().fetchValueOnly({
        content_type: "testimonial",
        "fields.page": "Home",
      });

      setTestimonials(
        results.map((item) => {
          return {
            avatar_url: getLinkToContentfulMedia(item, "avatar"),
            name: item.name,
            role: item.role,
            description: item.testimonial,
          };
        }),
      );
    }

    getStats();
    getTestimonials();
  }, []);

  return (
    <div className="home-page">
      <Hero />
      <AppViews />
      <FindLocalPlayers
        title={findLocalPlayersData.title}
        paragraph={findLocalPlayersData.paragraph}
        secondParagraph={findLocalPlayersData.secondParagraph}
        Button={findLocalPlayersData.Button}
      />
      <HowItWorks items={howItWorks()} className="home-how-it-works">
        <h2>{tx["How it works"]}</h2>
        <p className="text-grey-1">
          {
            tx[
              "You’re just seconds away from joining a thriving community of tennis, badminton, squash, table tennis and padel players."
            ]
          }
        </p>
      </HowItWorks>
      <LeaveFeedback />
      <Stats items={stats} />
      <FeaturedBy />
      <Testimonials
        carouselItems={testimonials}
        title={tx["Don’t just take our word for it"]}
        subtitle={tx["See what other players are saying about RacketPal."]}
      />
      <Partners />
      <AppBanner />
    </div>
  );
};

export default Home;
