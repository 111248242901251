import Image from "next/image";
import React from "react";
import { Container, Row } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useAppContext } from "../_context";

const imageHight = 100;
const partners = [
  {
    src: "better.png",
    width: 657,
    height: 320,
    url: "https://www.better.org.uk",
  },

  {
    src: "park-tennis-kingston.png",
    width: 3508,
    height: 2481,
    url: "https://clubspark.lta.org.uk/TennisInKingston",
  },
  {
    src: "lewisham.png",
    width: 2366,
    height: 2366,
    url: "https://lewisham.gov.uk",
  },
  {
    src: "richmond.png",
    width: 532,
    height: 143,
    url: "https://clubspark.lta.org.uk/playtennisrichmond",
  },
  {
    src: "inspireAll.png",
    width: 498,
    height: 203,
    url: "https://www.inspireall.com",
  },
  {
    src: "nta.png",
    width: 633,
    height: 239,
    url: "https://nationaltennis.org.uk",
  },
  {
    src: "surbiton.png",
    width: 851,
    height: 851,
    url: "https://www.surbiton.org",
  },
  {
    src: "west-side.png",
    width: 200,
    height: 200,
    url: "https://thewestsidetennisclub.com",
  },
  {
    src: "columbia.png",
    width: 1111,
    height: 1024,
    url: "http://gocolumbialions.com",
  },
  {
    src: "fantium.png",
    width: 372,
    height: 84,
    url: "https://www.fantium.com/?utm_source=website&utm_medium=sponsored_content&utm_campaign=racketpal&utm_content=website",
  },
];

const Partners = () => {
  const [{ tx }] = useAppContext();

  return (
    <section className="ourPartners-by-section">
      <Container>
        <Row>
          <h4 className="text-black text-center">{tx["Our Partners"]}</h4>

          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            breakpoints={{ 768: { slidesPerView: 5 } }}
            initialSlide={0}
            className="featured-by-icons d-flex m-auto flex-wrap no-gutters text-center col-md-10 align-items-center"
            autoplay={{
              delay: 2000,
            }}
            modules={[Autoplay]}
            direction="horizontal">
            {partners.map((item, index) => (
              <SwiperSlide key={index} id={index} className="align-self-center">
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  className="px-2">
                  <Image
                    src={`/img/partners/${item.src}`}
                    width={(imageHight * item.width) / item.height}
                    height={imageHight}
                    unoptimized={true}
                    priority={true}
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
